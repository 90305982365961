import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getEventList from "../../redux/actions/events/getEventList";
import { getAssignedLicenseAction } from "../../redux/actions/license/getAssignedLicenseAction";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import AssignedModule from "./AssignedModule";

const ManageModule = () => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [nodes, setNodes] = useState([]);
  const [modules, setModules] = useState([]);
  const productsData = useSelector((state) => state.productsData);
  const eventsData = useSelector((state) => state.eventsData);

  useEffect(() => {
    dispatch(getProductAction({ value: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAssignedLicenseAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEventList());
  }, [dispatch]);

  const getAssignedLicenseData = useSelector(
    (state) => state.getAssignedLicense
  );

  useEffect(() => {
    if (productsData?.data?.data?.length > 0) {
      setNodes(productsData.data.data);
    }
  }, [productsData]);

  useEffect(() => {
    if (getAssignedLicenseData?.data?.data?.length > 0) {
      setModules(getAssignedLicenseData.data.data);
    }
  }, [getAssignedLicenseData]);

  const handleUpdateModule = (rowData) => {
    setModalType("edit");
    setEditData(rowData);
    setOpenModal(true);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleUpdateModule(rowData)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  const renderCustomColumn = (rowData, column) => {
    const dataLength = JSON.parse(rowData[column.field])?.length || 0;
    let displayData;
    if (dataLength > 3) {
      displayData = (
        <>
          {JSON.parse(rowData[column?.field])?.slice(0, 3)?.join(", ")}
          <span className="ml-1 font-bold">+{dataLength - 3}</span>
        </>
      );
    } else {
      displayData = JSON.parse(rowData[column.field]).join(", ");
    }

    return <span>{displayData}</span>;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="ml-1 p-1 text-sm font-normal border-1 border-gray-400"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid py-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6">
                        <h5 className="text-xl">Manage Module</h5>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6 text-end mb-1">
                        <button
                          className="btn btn-red"
                          onClick={() => {
                            setOpenModal(true);
                            setModalType("add");
                            setEditData([]);
                          }}
                        >
                          Assign Module to Node
                        </button>
                      </div>
                    </div>

                    <div className="table-card">
                      <DataTable
                        value={nodes}
                        filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={[].length}
                        className="bg-none"
                        showGridlines
                      >
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="name"
                          header="Node Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="assign_module"
                          header="Module Name"
                          body={renderCustomColumn}
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AssignedModule
          setOpenModal={setOpenModal}
          nodeData={nodes}
          moduleData={modules}
          modalType={modalType}
          editData={editData}
        />
      )}
    </>
  );
};

export default ManageModule;
