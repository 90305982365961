import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAssignedEvent } from "../../redux/actions/events/addAssignedEvent";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import { clearaddAssignedEventsState } from "../../redux/reducers/events/addAssignedEventReducer";
import { assignedEventValidation } from "../../validations/assignedEventValidation";

const EventsForm = ({ editModal, setIsEditModal, editData, eventsData }) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [selectedNode, setselectedNode] = useState(editModal ? editData : null);
  const [selectedEvent, setselectedEvent] = useState(
    editModal
      ? JSON.parse(editData?.assign_event)?.map(
          (i) => eventsData?.data?.filter((e) => e.table_name === i)?.[0]
        )
      : null
  );
  const [nodes, setNodes] = useState([]);
  const [events, setEvents] = useState([]);
  const productsData = useSelector((state) => state.productsData);
  const assignedEvent = useSelector((state) => state.addAssignedEventReducer);

  useEffect(() => {
    dispatch(getProductAction({ value: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (productsData?.data?.data?.length > 0) {
      setNodes(productsData.data.data);
    }
  }, [productsData]);

  useEffect(() => {
    if (eventsData?.data?.length > 0) {
      setEvents(eventsData.data);
    }
  }, [eventsData]);

  const handleSubmit = () => {
    const updatedData = {
      product_id: selectedNode?.id ? String(selectedNode?.id) : "",
      events: selectedEvent
        ? selectedEvent?.map((item) => item?.table_name)
        : [],
    };
    const errors = assignedEventValidation(updatedData);
    setError(errors);
    if (Object.keys(errors).length === 0) {
      dispatch(addAssignedEvent(updatedData));
    } else {
      setError(errors);
    }
  };

  useEffect(() => {
    if (assignedEvent?.data?.status === true) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: assignedEvent?.data.message,
        life: 3000,
      });
      dispatch(clearaddAssignedEventsState());
      //   setTimeout(() => {
      //     dispatch(getAssignedLicenseAction());
      //   }, 1500);
    } else if (assignedEvent?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: assignedEvent?.data.message,
        life: 3000,
      });
      dispatch(clearaddAssignedEventsState());
    } else if (assignedEvent?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: assignedEvent.error,
        life: 3000,
      });
      dispatch(clearaddAssignedEventsState());
    }
  }, [assignedEvent, dispatch]);
  return (
    <>
      <div className="row p-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="form-group assigned-item">
            <div className="flex">
              <label className="text-[14px]">Select Node</label>
              <span className="text-red-700 pl-1">*</span>
            </div>
            <Dropdown
              filter
              value={selectedNode}
              onChange={(e) => {
                setselectedNode(e.value);
                setError({ ...error, product_id: "" });
              }}
              options={nodes}
              optionLabel="name"
              placeholder="Select a Node"
              className="w-full md:w-14rem h-[3rem] !border border-[#ced4da] hover:border-[#fe4c4c] rounded-none z-100"
            />
            {error?.product_id && (
              <p className="text-red-600 text-[12px]">{error?.product_id}</p>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="flex">
            <label className="text-[14px]">Select Events</label>
            <span className="text-red-700 pl-1">*</span>
          </div>
          <div className="card flex justify-center">
            <MultiSelect
              title={selectedEvent?.map((element) => element?.name)}
              value={selectedEvent}
              filter
              onChange={(e) => {
                setselectedEvent(e.value);
                setError({ ...error, events: "" });
              }}
              options={events}
              optionLabel="table_name"
              display="chip"
              placeholder="Select Events"
              maxSelectedLabels={3}
              className="w-full md:w-20rem !border border-[#ced4da] hover:border-[#fe4c4c]"
            />
            {error?.events && (
              <p className="text-red-600 text-[12px]">{error?.events}</p>
            )}
          </div>
        </div>
      </div>
      <div className="row p-3">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
          <button className="btn btn-red" onClick={handleSubmit}>
            Submit
          </button>
          <button
            className="btn btn-outline-secondary ml-1"
            onClick={
              editModal
                ? () => setIsEditModal(false)
                : () => navigate("/manageAssignedEvent")
            }
          >
            Cancel
          </button>
        </div>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default EventsForm;
