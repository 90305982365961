import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { getUsersWithNode } from "../../redux/actions/admin/getUsersWithNode";
import { transformData } from "../../utils/generateRecords";

const AdminDetailsPage = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getUsers = useSelector((state) => state.getUsersWithNodeReducer);

  useEffect(() => {
    dispatch(getUsersWithNode());
  }, [dispatch]);

  useEffect(() => {
    if (getUsers?.data?.data?.length > 0) {
      setIsLoading(false);
      setUsers(getUsers.data.data);
    }
  }, [getUsers]);

  useEffect(() => {
    if (getUsers?.loading) {
      setIsLoading(true);
    }
  }, [getUsers]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleView = (rowData) => {
    navigate(`/details/${rowData?.node_id}/${rowData?.userId}/1`);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleView(rowData)}>
          View <FontAwesomeIcon icon={faEye} />
        </button>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-between">
          <div className="flex items-center">
            <p className="text-xl font-normal font-[sans-serif]">
              All Device List
            </p>
          </div>
          <span className="flex items-center">
            <span className="font-normal">Search:</span>
            <InputText
              className="ml-1 p-1 text-sm font-normal border-1 border-gray-400"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid py-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="table-card">
                      <DataTable
                        rowGroupMode="rowspan"
                        groupRowsBy="representative.name"
                        value={transformData(users)}
                        filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={[].length}
                        // loading={getUsers?.loading}
                        className="bg-none"
                        showGridlines
                        emptyMessage={
                          isLoading ? "Loading..." : "No devices found."
                        }
                      >
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="name"
                          header="User Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="node_name"
                          header="Node name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="description"
                          header="Description"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="site_name"
                          header="Site name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center text-nowrap"
                          field="site_area_location"
                          header="Location"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 text-[#FE4C4C] text-center"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDetailsPage;
