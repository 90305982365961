export const cityData = [
  {
    sr_no: 1,
    country: "USA",
    state: "California",
    city: "Los Angeles",
    status: "Active",
    Action: "View",
  },
  {
    sr_no: 2,
    country: "USA",
    state: "New York",
    city: "New York",
    status: "Inactive",
    Action: "Edit",
  },
  {
    sr_no: 3,
    country: "UK",
    state: "England",
    city: "London",
    status: "Active",
    Action: "Delete",
  },
  {
    sr_no: 4,
    country: "Canada",
    state: "Ontario",
    city: "Toronto",
    status: "Active",
    Action: "View",
  },
  {
    sr_no: 5,
    country: "Germany",
    state: "Bavaria",
    city: "Munich",
    status: "Inactive",
    Action: "Edit",
  },
  {
    sr_no: 6,
    country: "Australia",
    state: "Victoria",
    city: "Melbourne",
    status: "Active",
    Action: "View",
  },
  {
    sr_no: 7,
    country: "France",
    state: "Île-de-France",
    city: "Paris",
    status: "Inactive",
    Action: "Edit",
  },
  {
    sr_no: 8,
    country: "Japan",
    state: "Tokyo",
    city: "Tokyo",
    status: "Active",
    Action: "Delete",
  },
  {
    sr_no: 9,
    country: "India",
    state: "Maharashtra",
    city: "Mumbai",
    status: "Active",
    Action: "View",
  },
  {
    sr_no: 10,
    country: "Brazil",
    state: "São Paulo",
    city: "São Paulo",
    status: "Inactive",
    Action: "Edit",
  },
  {
    sr_no: 11,
    country: "Brazil",
    state: "São Paulo",
    city: "São Paulo",
    status: "Inactive",
    Action: "Edit",
  },
  {
    sr_no: 12,
    country: "Brazil",
    state: "São Paulo",
    city: "São Paulo",
    status: "Inactive",
    Action: "Edit",
  },
];

export const categoryData = [
  { sr_no: 1, category_name: "Electronics", status: "Active", action: "View" },
  { sr_no: 2, category_name: "Clothing", status: "Inactive", action: "Edit" },
  { sr_no: 3, category_name: "Books", status: "Active", action: "Delete" },
  {
    sr_no: 4,
    category_name: "Home & Kitchen",
    status: "Active",
    action: "View",
  },
  {
    sr_no: 5,
    category_name: "Toys & Games",
    status: "Inactive",
    action: "Edit",
  },
  {
    sr_no: 6,
    category_name: "Sports & Outdoors",
    status: "Active",
    action: "View",
  },
  {
    sr_no: 7,
    category_name: "Beauty & Personal Care",
    status: "Inactive",
    action: "Edit",
  },
  {
    sr_no: 8,
    category_name: "Health & Household",
    status: "Active",
    action: "Delete",
  },
  { sr_no: 9, category_name: "Automotive", status: "Active", action: "View" },
  {
    sr_no: 10,
    category_name: "Grocery & Gourmet Food",
    status: "Inactive",
    action: "Edit",
  },
  {
    sr_no: 11,
    category_name: "Health & Household",
    status: "Active",
    action: "Delete",
  },
  { sr_no: 12, category_name: "Automotive", status: "Active", action: "View" },
  {
    sr_no: 13,
    category_name: "Grocery & Gourmet Food",
    status: "Inactive",
    action: "Edit",
  },
];

export const usersData = [
  {
    sr_no: 1,
    id: "ABC123",
    license: "XYZ789",
    user_name: "john_doe",
    email: "john@example.com",
    mobile_no: "1234567890",
  },
  {
    sr_no: 2,
    id: "DEF456",
    license: "UVW123",
    user_name: "jane_doe",
    email: "jane@example.com",
    mobile_no: "9876543210",
  },
  {
    sr_no: 3,
    id: "GHI789",
    license: "RST456",
    user_name: "bob_smith",
    email: "bob@example.com",
    mobile_no: "5556667777",
  },
  {
    sr_no: 4,
    id: "JKL012",
    license: "MNO789",
    user_name: "alice_smith",
    email: "alice@example.com",
    mobile_no: "3334445555",
  },
  {
    sr_no: 5,
    id: "PQR345",
    license: "EFG678",
    user_name: "sam_jones",
    email: "sam@example.com",
    mobile_no: "9998887777",
  },
  {
    sr_no: 6,
    id: "STU678",
    license: "HIJ012",
    user_name: "emily_wang",
    email: "emily@example.com",
    mobile_no: "1112223333",
  },
  {
    sr_no: 7,
    id: "VWX901",
    license: "KLM345",
    user_name: "michael_brown",
    email: "michael@example.com",
    mobile_no: "7778889999",
  },
  {
    sr_no: 8,
    id: "YZA234",
    license: "NOP567",
    user_name: "susan_miller",
    email: "susan@example.com",
    mobile_no: "4445556666",
  },
  {
    sr_no: 9,
    id: "BCD567",
    license: "QRS901",
    user_name: "david_clark",
    email: "david@example.com",
    mobile_no: "6667778888",
  },
  {
    sr_no: 10,
    id: "EFG890",
    license: "TUV234",
    user_name: "lisa_davis",
    email: "lisa@example.com",
    mobile_no: "2223334444",
  },
];

export const productData = [
  {
    sr_no: 1,
    product_name: "Laptop",
    device_name: "Dell XPS 13",
    serial_number: "ABC123",
    license_number: "XYZ789",
  },
  {
    sr_no: 2,
    product_name: "Smartphone",
    device_name: "iPhone 12",
    serial_number: "DEF456",
    license_number: "UVW123",
  },
  {
    sr_no: 3,
    product_name: "Tablet",
    device_name: "iPad Pro",
    serial_number: "GHI789",
    license_number: "RST456",
  },
  {
    sr_no: 4,
    product_name: "Desktop",
    device_name: "HP Pavilion",
    serial_number: "JKL012",
    license_number: "MNO789",
  },
  {
    sr_no: 5,
    product_name: "Monitor",
    device_name: "Samsung Odyssey G7",
    serial_number: "PQR345",
    license_number: "EFG678",
  },
  {
    sr_no: 6,
    product_name: "Printer",
    device_name: "Epson EcoTank",
    serial_number: "STU678",
    license_number: "HIJ012",
  },
  {
    sr_no: 7,
    product_name: "Router",
    device_name: "TP-Link Archer AX6000",
    serial_number: "VWX901",
    license_number: "KLM345",
  },
  {
    sr_no: 8,
    product_name: "Camera",
    device_name: "Canon EOS R5",
    serial_number: "YZA234",
    license_number: "NOP567",
  },
  {
    sr_no: 9,
    product_name: "Headphones",
    device_name: "Sony WH-1000XM4",
    serial_number: "BCD567",
    license_number: "QRS901",
  },
  {
    sr_no: 10,
    product_name: "Keyboard",
    device_name: "Logitech G Pro X",
    serial_number: "EFG890",
    license_number: "TUV234",
  },
];

export const assignedLicenceData = [
  {
    full_name: "John Doe",
    product_name: ["Laptop", "Smartphone", "Desktop", "Monitor", "Printer"],
  },
  {
    full_name: "Jane Smith",
    product_name: ["Tablet", "Smartphone", "Camera", "Headphones"],
  },
  {
    full_name: "Michael Johnson",
    product_name: ["Smartwatch", "Desktop", "Router", "Keyboard"],
  },
  {
    full_name: "Emily Brown",
    product_name: ["Tablet", "Monitor", "Mouse", "Keyboard"],
  },
  {
    full_name: "David Lee",
    product_name: ["Laptop", "Speaker", "Game Console", "Headphones"],
  },
  {
    full_name: "Jennifer Garcia",
    product_name: ["Monitor", "Printer", "Smartphone", "Keyboard"],
  },
  {
    full_name: "Chris Taylor",
    product_name: ["Smartphone", "Headphones", "VR Headset"],
  },
  {
    full_name: "Amanda Martinez",
    product_name: ["Tablet", "Laptop", "Camera"],
  },
  {
    full_name: "Daniel Rodriguez",
    product_name: ["Desktop", "Monitor", "Laptop"],
  },
  {
    full_name: "Sarah Wilson",
    product_name: ["Smartwatch", "Printer", "Tablet"],
  },
  {
    full_name: "Jessica Nguyen",
    product_name: ["Smartphone", "Desktop", "Headphones"],
  },
  {
    full_name: "Matthew Garcia",
    product_name: ["Tablet", "Smartphone", "Router"],
  },
];

export const allusersData = [
  {
    sr_no: 1,
    product_name: "Laptop",
    device_name: "Dell XPS 13",
    serial_number: "ABC123",
    license_number: "XYZ789",
    action: "confirm",
  },
  {
    sr_no: 2,
    product_name: "Smartphone",
    device_name: "iPhone 12",
    serial_number: "DEF456",
    license_number: "UVW123",
    action: "pending",
  },
  {
    sr_no: 3,
    product_name: "Tablet",
    device_name: "iPad Pro",
    serial_number: "GHI789",
    license_number: "RST456",
    action: "confirm",
  },
  {
    sr_no: 4,
    product_name: "Desktop",
    device_name: "HP Pavilion",
    serial_number: "JKL012",
    license_number: "MNO789",
    action: "pending",
  },
  {
    sr_no: 5,
    product_name: "Monitor",
    device_name: "Samsung Odyssey G7",
    serial_number: "PQR345",
    license_number: "EFG678",
    action: "confirm",
  },
];

export const linksData = [
  // {
  //   to: "/manageCategory",
  //   label: "Manage Category",
  // },
  {
    to: "/manageUser",
    label: "Manage User",
  },
  {
    to: "/manageNode",
    label: "Manage Node",
  },
  {
    to: "/assignedLicense",
    label: "Assign Node",
  },
  {
    to: "/manageModule",
    label: "Assign Module",
  },
  {
    to: "/manageAssignedEvent",
    label: "Assign Event",
  },
];

export const graphTicks = [
  { value: -50 },
  { value: -40 },
  { value: -30 },
  { value: -20 },
  { value: -10 },
  { value: 0 },
  { value: 10 },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50 },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  { value: 100 },
  { value: 110 },
  { value: 120 },
  { value: 130 },
  { value: 140 },
  { value: 150 },
];

export const graphCTPTicks = [
  { value: 0 },
  { value: 10 },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50 },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  { value: 100 },
  { value: 110 },
  { value: 120 },
  { value: 130 },
  { value: 140 },
  { value: 150 },
];

export const standardTicks = [
  { value: -50 },
  { value: -30 },
  { value: -10 },
  { value: 10 },
  { value: 30 },
  { value: 50 },
  { value: 70 },
  { value: 90 },
  { value: 110 },
  { value: 140 },
  { value: 160 },
  { value: 180 },
  { value: 200 },
  { value: 220 },
  { value: 240 },
  { value: 260 },
  { value: 280 },
  { value: 300 },
];

export const controlPanelTabs = [
  {
    id: "1",
    name: "Devices",
    value: "devices",
  },
  {
    id: "2",
    name: "Modules",
    value: "modules",
  },
  {
    id: "3",
    name: "Alarms",
    value: "alarms",
  },
  {
    id: "4",
    name: "Trips",
    value: "trips",
  },
];

export const navLinks = [
  { path: "/home", label: "Dashboard", icon: "faDashboard" },
  { path: "/control-panel/all", label: "Control Panel", icon: "faSolarPanel" },
  { path: "/device-list", label: "Device List", icon: "faList" },
  {
    path: "/device-status",
    label: "Device Status",
    icon: "faTachographDigital",
  },
];

export const AllControlPanelTabs = [
  {
    id: "1",
    name: "Offline Device",
    value: "Offline Device",
  },
  {
    id: "2",
    name: "Temporary Offline Device",
    value: "Temporary Offline Device",
  },
  {
    id: "3",
    name: "Long Term Offline Device",
    value: "Long Term Offline Device",
  },
  {
    id: "4",
    name: "Offline Module",
    value: "Offline Module",
  },
  {
    id: "5",
    name: "Temporary Offline Module",
    value: "Temporary Offline Module",
  },
  {
    id: "6",
    name: "Long Term Offline Module",
    value: "Long Term Offline Module",
  },
  {
    id: "7",
    name: "Acknowledged Alarm",
    value: "Acknowledged Alarm",
  },
  {
    id: "8",
    name: "Unacknowledged Alarm",
    value: "Unacknowledged Alarm",
  },
  {
    id: "9",
    name: "Acknowledged Trip",
    value: "Acknowledged Trip",
  },
  {
    id: "10",
    name: "Unacknowledged Trip",
    value: "Unacknowledged Trip",
  },
  {
    id: "11",
    name: "No Filters",
    value: "All",
  },
];

export const AllCTPFiltersOptions = [
  {
    id: "1",
    name: "1 day",
    value: 1,
  },
  {
    id: "2",
    name: "3 days",
    value: 3,
  },
  {
    id: "3",
    name: "7 days",
    value: 7,
  },
  {
    id: "4",
    name: "15 days",
    value: 15,
  },
  {
    id: "5",
    name: "30 days",
    value: 30,
  },
  {
    id: "6",
    name: "50 days",
    value: 50,
  },
  {
    id: "7",
    name: "100 days",
    value: 100,
  },
  {
    id: "8",
    name: "150 days",
    value: 150,
  },
];
