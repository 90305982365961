import moment from "moment";

// const excludedKeys = [
//   "id",
//   "DateTime",
//   "created_at",
//   "SerialNumber",
//   "RLYOTA",
//   "RLYOTT",
//   "RLYWTA",
//   "RLYWTT",
//   "RLYWT2A",
//   "RLYWT2T",
//   "RLYWT3A",
//   "RLYWT3T",
//   "FanExeciserStatus",
//   "RLY1",
//   "RLY2",
//   "RLY3",
//   "RLY4",
//   "Relay1",
//   "Relay2",
//   "Relay3",
//   "Relay4",
//   "Relay5",
//   "ForceHoldRelay",
//   "LowerRelay",
//   "MODE",
//   "OverVoltage",
//   "RaiseRelay",
//   "UnderVoltage",
// ];

export const convertDataToHighchartsFormat = (data) => {
  const seriesData = {};
  const IST_OFFSET = 19800 * 1000; // IST offset in milliseconds

  Object.keys(data).forEach((device) => {
    Object.keys(data[device]).forEach((key) => {
      const seriesName = `${key}`;
      if (!seriesData[seriesName]) {
        seriesData[seriesName] = [];
      }
      data[device][key].forEach((item) => {
        const dateTime =
          moment(item.dateTime, "YYYY-MM-DD hh:mm:ss").valueOf() + IST_OFFSET;
        seriesData[seriesName].push([dateTime, item.value]);
      });
    });
  });

  return seriesData;
};

export const rearrangeArray = (arr) => {
  const nonEliteItems = arr.filter((item) => item.name !== "ELITE300");
  const eliteItems = arr.filter((item) => item.name === "ELITE300");
  return [...nonEliteItems, ...eliteItems];
};

export const modifyKeysName = (key) => {
  switch (key) {
    case "onlineDevice":
      return "Online";
    case "TemporaryOffline":
      return "Temporary Offline";
    case "LongTermOffline":
      return "Long Term Offline";
    default:
      return "Offline";
  }
};

export const converDate = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  const hour = dateString.substring(8, 10);
  const minute = dateString.substring(10, 12);

  // Create a formatted date string
  const formattedDate = `${day}-${month}-${year} ${hour}:${minute}`;
  return formattedDate;
};

export const filterDataByKeys = (data) => {
  const selectedKeys = [
    "id",
    "DateTime",
    "RPhaseNeutralVoltage",
    "YPhaseNeutralVoltage",
    "BPhaseNeutralVoltage",
    "FrequencyHz",
    "RYVoltage",
    "YBVoltage",
    "BRVoltage",
    "AverageVoltage",
    "RPhaseLineCurrent",
    "YPhaseLineCurrent",
    "BPhaseLineCurrent",
    "created_at",
  ];
  return data?.length > 0
    ? data?.map((item) => {
        const filteredItem = {};
        selectedKeys.forEach((key) => {
          if (key in item) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      })
    : [];
};
